<template>
  <div>
    <!-- 列表 -->
    <div v-if="list.length" class="list">
      <div v-for="(item, index) in list" :key="index" class="list-item-box">
        <div class="flex-between">
          <div>
            <div class="text-6B7280 font-32">
             <span class="text-6B7280 font-32 font-bold mt-24">{{ item.projectName || '飞逸美选' }}</span>
              <!-- 支付项目:<span class="text-6B7280 font-32 font-bold mt-24">{{ item.productName || '飞逸美选' }}</span> -->
            </div>
          </div>
          <div class="font-28 text-CA8A04" :class="{
            'text-16A34A': item.status === 'SUCCESS',
            'text-FF4D4F': item.status === 'FAIL',
          }">扣款{{ item.statusStr || '中' }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">分期期数</div>
          <div class="text-000 font-32 ">{{ item.totalPeriod }}期</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">还款期数</div>
          <div class="text-000 font-32 ">{{ item.periods ? `第${item.periods}期` : '提前结清' }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">支付金额</div>
          <div class="text-000 font-32 ">¥{{ item.amount }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">还款时间</div>
          <div class="text-000 font-32">{{ formatDate(item.backedDate) }} {{ item.backedTime }}</div>
        </div>
      </div>
    </div>
    <template v-else>
      <NoData />
    </template>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NoData from '@/components/noData'
import API from '@/server/api'
import { formatDate } from '@/utils/routh'

export default {
  components: { NoData },
  setup() {
    const list = ref([]);
    onMounted(() => {
      getList()
    })
    const getList = async () => {
      try {
        let params = {
          clientNo: localStorage.getItem("clientNo"),
          loanNo: localStorage.getItem("loanNo")
        }
        const { code, result } = await API.getBackList(params)
        if (code === '200') {
          result.forEach(item => { 
            if (item.backPeriod) {
              let treeList = JSON.parse(item.backPeriod);
              let filteredTree = treeList.filter(node => node !== null);
              let resultStr = filteredTree.join(',');
              item.periods = resultStr
            }
          })
          list.value = result
        }
      } catch (error) {
        console.log(error);
      }
    }
    return {
      getList,
      list,
      formatDate
    };
  }
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-item-box {
  width: 690px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px #E1EBFF;
  border-radius: 24px;
  margin-top: 24px;
  padding: 29px 36px 30px 30px;
  box-sizing: border-box;
}</style>
