<template>
  <div>
    <!-- 导航 -->
    <div class="nav">
      <van-tabs v-model="active" @click="changeNav"  color="#8E6FF7" title-active-color="#8E6FF7">
        <van-tab v-for="item in navList" :title="item.name" :key="item.id"  />
      </van-tabs>
    </div>
    <!-- 列表 -->
    <div v-if="list.length" class="list">
      <div v-for="(item, index) in list" :key="index" class="list-item-box" @click="jumpDetails(item)">
        <div class="flex-between">
          <div>
            <div class="text-6B7280 font-32 font-bold">{{ item.productName }}</div>
            <div class="font-40 font-bold text-8E6FF7 mt-16 FZFW">¥{{ $filters.formatMoney(item.loanAmount) }}</div>
          </div>
          <div class="font-32" :class="{
            'text-16A34A': item.loanStatus === 'SETTLED',
            'text-8E6FF7': item.loanStatus === 'BACKING',
            'text-CA8A04': item.loanStatus === 'SUBMITED',
          }">{{ item.loanStatusStr }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款期数</div>
          <div class="text-000 font-32">{{ item.loanLimit }}期</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款方式</div>
          <div class="text-000 font-32">{{ item.loanTypeStr }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款时间</div>
          <div class="text-000 font-32">{{ formatDate(item.loanTime) }}</div>
        </div>
      </div>
    </div>
    <template v-else>
      <NoData />
    </template>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NoData from '@/components/noData'
import API from '@/server/api'
import { Jump, formatDate } from '@/utils/routh'

export default {
  components: { NoData },
  setup() {
    const navList = [
      {
        name: "全部",
        id: ''
      },
      {
        name: "申请中",
        id: 'SUBMITED'
      },
      {
        name: "还款中",
        id: 'BACKING'
      },
      {
        name: "已结清",
        id: 'SETTLED'
      }
    ];
    const active = ref(0);
    const list = ref([]);
    onMounted( () => {
      getList()
    })
    const changeNav = (id) => {
      active.value = id 
      getList()
    }
    const getList = async () => {
      try {
        let params = {
          clientNo: localStorage.getItem("clientNo"),
          loanStatus: navList[active.value].id
        }
        const { code, result } = await API.getLoanList(params)
        if (code === '200') { 
          list.value = result
        }
      } catch (error) {
        console.log(error);
      }
    }
    const jumpDetails = (item) => {
      let { loanNo, loanLimit, loanStatus } = item
      Jump('/LoanDetails', { loanNo, loanLimit, loanStatus })
    }
    return {
      active,
      navList,
      getList,
      changeNav,
      list,
      Jump,
      formatDate,
      jumpDetails
    };
  }
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-item-box {
  width: 690px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px #E1EBFF;
  border-radius: 24px;
  margin-top: 24px;
  padding: 29px 36px 30px 30px;
  box-sizing: border-box;
}</style>
