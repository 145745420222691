<template>
  <div class="details">
      <template v-if="isShow">
        <div style="height: 24px"></div>
        <div class="bg-white mx-24 p-24 item">
          <div class="mb-32 text-000 font-40"> {{ loanInfo.productName || '飞逸美选' }} </div>
          <van-divider :margin="12" />
          <div v-for="item in formList" :key="item.key">
            <div class="flex-between mb-24">
              <div class="text-6B7280 font-32">{{ item.label }}</div>
              <div v-if="item.type === 'date'" class="text-000 font-32">{{ formatDate(loanInfo[item.key]) }}</div>
              <div v-else-if="item.type === 'idNo'" class="text-000 font-32">{{ loanInfo[item.key].replace(/^(.{5})(?:\d+)(.{3})$/, "$1**********$2") }}</div>
              <div v-else-if="item.type === 'name'" class="text-000 font-32">{{ substring(loanInfo[item.key]) }}</div>
              <div v-else-if="item.type === 'money'" class="text-000 font-32 FZFW"> ¥{{ $filters.formatMoney(loanInfo[item.key]) }}</div>
              <div v-else class="text-000 font-32">
                {{ loanInfo[item.key] }}
                <span v-if="item.unit">{{ item.unit }}</span>
              </div>
            </div>
          </div>
          <template v-if="loan_Status != 'CANCELED'">
            <van-divider :margin="12" />
            <div class="mb-32 text-000 font-40"> 还款跟踪（<span class="text-CA8A04">{{ loanInfo.currentPeriod}}</span>/<span>{{ loanInfo.loanLimit}}</span>） </div>
            <van-steps direction="vertical" :active="loanInfo.currentPeriod - 1">
              <van-step v-for="(item, idx) in schedule" :key="idx">
                <div class="flex-between">
                  <div>
                    <h3> 第{{ idx + 1 }}期</h3>
                    <p>{{ formatDate(item.planDate) }}</p>
                  </div>
                  <div>{{ item.status }}</div>
                </div>
              </van-step>
            </van-steps>
          </template>
        </div>
      </template>
      <template v-else>
        <NoData />
      </template>
    </div>

</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router'
import NoData from '@/components/noData'
import API from '@/server/api'
import { Jump, formatDate } from '@/utils/routh'

export default {
  components: { NoData },

  setup() {
    const Route = useRoute();
    const loanno = ref('');
    const loan_Limit = ref('');
    const loan_Status = ref('');
    const loanInfo = ref({});
    const schedule = ref([]);
    const isShow = ref(false);
    const formList = [
      {
        label: '借款金额:',
        key: 'loanAmount',
        type: 'money',
      },
      {
        label: '借款日期:',
        type: 'date',
        key: 'loanDate',
      },
      {
        label: '借款期限:',
        key: 'loanLimit',
        unit: '月'
      },
      {
        label: '还款方式:',
        key: 'loanTypeStr',
      },
      {
        label: '借款人姓名:',
        key: 'clientName',
        type: 'name',
      },
      {
        label: '借款人身份证:',
        key: 'idNo',
        type: 'idNo',
      }
    ]

    onMounted(() => {
      const { loanNo, loanLimit, loanStatus } = Route.query
      console.log(loanLimit, loanStatus, '-loanLimit, loanStatus-');
      if (loanNo) {
        loanno.value = loanNo
        loan_Limit.value = loanLimit
        loan_Status.value = loanStatus
        getDetails()
      }
    })

    const substring = (name) => {
      if (!name) {
        return name
      }
      return name.replace(/(^.{1})(.+)$/g, function (...args) {
        let tempStr = ''
        if (args[2] && args[2].length) {
          tempStr = Array.from({
            length: args[2].length
          }).join('*')
        }
        let res = name.length === 2 ? ('*' + name.slice(-1)) : (args[1] + tempStr + name.slice(-1))
        return res
      })
    }

    const getDetails = async () => {
      try {
        const { code, result } = await API.getLoanDetail(loanno.value)
        if (code === '200') { 
          loanInfo.value = result
          schedule.value = result.backedPlans
          isShow.value = true
          if (loan_Status .value === 'SETTLED') { 
            loanInfo.value.currentPeriod = loan_Limit.value
          }
          return
        }
        isShow.value = false
      } catch (error) {
        isShow.value = false
        console.log(error);
      }
    }
    return {
      loanno,
      loanInfo,
      isShow,
      getDetails,
      formList,
      formatDate,
      substring,
      schedule,
      loan_Limit,
      loan_Status
    };
  }
};
</script>
<style lang="less" scoped>
.details {
  background: #F7F6F9 !important;
  height: 100%;
}
.item {
  border-radius: 24px;
}
:deep(.van-step__circle) {
  width: 15px !important;
  height: 15px !important;
}
</style>
