<template>
  <div>
    <!-- 列表 -->
    <div v-if="list.length" class="list">
      <div v-for="(item, index) in list" :key="index" class="list-item-box" @click="Jump('/OrderDetails', { loanNo: item.loanNo })">
        <div class="flex-between">
          <div>
            <div class="font-40 font-bold text-8E6FF7 mt-16 FZFW">¥{{ $filters.formatMoney(item.amount) }}</div>
          </div>
          <div class="font-32" :class="{
            'text-16A34A': item.status === 'SUCCESS',
            'text-8E6FF7': item.status === 'INIT', // 申请中
            'text-CA8A04': item.status === 'CANCEL', // 已作废
          }">{{ getStatus(item.status) }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款期限</div>
          <div class="text-000 font-32">{{ item.loanNum }}月</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款时间</div>
          <div class="text-000 font-32">{{ formatDate(item.loanTime) }}</div>
        </div>
        <div class="mt-24 flex-between">
          <div class="text-6B7280 font-32">借款编号</div>
          <div class="text-000 font-32">{{ item.loanNo }}</div>
        </div>
      </div>
    </div>
    <template v-else>
      <NoData />
    </template>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NoData from '@/components/noData'
import API from '@/server/api'
import { Jump, formatDate } from '@/utils/routh'

export default {
  components: { NoData },
  setup() {
    const list = ref([]);
    onMounted( () => {
      getList()
    })
    const changeNav = (id) => {
      active.value = id 
      getList()
    }
    const getList = async () => {
      try {
        let clientNo = localStorage.getItem("clientNo")
        const { code, result } = await API.getOrderList(clientNo)
        if (code === '200') {
          list.value = result || []
        }
      } catch (error) {
        console.log(error);
      }
    }
    const getStatus = (status) => {
      if (status === 'SUCCESS') {
        return '已完成'
      } else if (status === 'INIT') {
        return '申请中'
      } else if (status === 'CANCEL') {
        return '已作废'
      }
    }
    return {
      getList,
      changeNav,
      list,
      Jump,
      formatDate,
      getStatus
    };
  }
};
</script>
<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-item-box {
  width: 690px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px #E1EBFF;
  border-radius: 24px;
  margin-top: 24px;
  padding: 29px 36px 30px 30px;
  box-sizing: border-box;
}</style>
