<template>
  <div class="details">
      <template v-if="isShow">
        <div style="height: 24px"></div>
        <div class=" bg-white px-24">
          <div v-for="(item, index) in formList" :key="index" class="item">
            <div class="flex-between h-50">
              <div class="text-6B7280 font-32">{{ item.label }}</div>
              <div v-if="item.type === 'idNo'" class="text-000 font-32">
                {{ loanInfo[item.key].replace(/^(.{4})(?:\d+)(.{4})$/, "$1 **** **** $2") }}
              </div>
              <div v-else-if="item.type === 'money'" class="text-000 font-32">
                ¥{{ $filters.formatMoney(loanInfo.loanAmount) }}
              </div>
              <div v-else class="text-000 font-32">
                {{ loanInfo[item.key] }}
                <span v-if="item.unit">{{ item.unit }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 项目 -->
        <div v-if="loanInfo.projects.length" class="bg-white px-24 mt-24 item">
          <div class="flex-between h-50 ">
            <div class="text-6B7280 font-32">申请项目</div>
            <div class="text-000 font-32">
              {{ loanInfo.projects.length }}项
            </div>
          </div>
          <!--  -->
          <div class="h-50 flex alc" v-for="(item, index) in loanInfo.projects" :key="index">
            <div class="text-000 font-32">{{ index + 1}}、{{ item }}</div>
          </div>
          <!--  -->
          <div class="flex-between h-50 ">
            <div class="text-6B7280 font-32">项目总金额</div>
            <div class="text-000 font-32">
              ¥{{ $filters.formatMoney(loanInfo.loanAmount) }}
            </div>
          </div>
        </div>
        <!-- 提交时间 -->
        <div class="bg-white px-24 mt-24 item">
          <div class="flex-between h-50">
            <div class="text-6B7280 font-32">提交时间</div>
            <div class="text-000 font-32">
              {{ formatDate(loanInfo.createTime) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <NoData />
      </template>
    </div>

</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router'
import NoData from '@/components/noData'
import API from '@/server/api'
import { Jump, formatDate } from '@/utils/routh'

export default {
  components: { NoData },

  setup() {
    const Route = useRoute();
    const loanno = ref('');
    const loanInfo = ref({});
    const schedule = ref([]);
    const isShow = ref(false);
    const formList = [
      {
        label: '分期金额:',
        key: 'loanAmount',
        type: 'money'
      },
      {
        label: '分期期数:',
        key: 'loanLimit',
        unit: '期'
      },
      {
        label: '还款银行卡:',
        key: 'bankAccount',
        type: 'idNo'
      }
    ]
    
    onMounted(() => {
      const { loanNo } = Route.query
      if (loanNo) {
        loanno.value = loanNo
        getDetails()
      }
    })

    const getDetails = async () => {
      try {
        const { code, result } = await API.getOrderDetail(loanno.value)
        if (code === '200') { 
          loanInfo.value = result
          schedule.value = result.backedPlans
          isShow.value = true
          return
        }
        isShow.value = false
      } catch (error) {
        isShow.value = false
        console.log(error);
      }
    }
    return {
      loanno,
      loanInfo,
      isShow,
      getDetails,
      formList,
      formatDate,
      schedule
    };
  }
};
</script>
<style lang="less" scoped>
.details {
  background: #F7F6F9 !important;
  height: 100%;
}
.item {
  .h-50:last-child {
    border-bottom: none;
  }
}
.h-50 {
  height: 100px;
  border-bottom: 1px solid #E5E5E5;
}
</style>
