<template>
  <div class="base-page">
    <div style="flex: 1; overflow-y: auto">
      <!-- 个人信息 -->
      <div class="base-main">
        <!-- 地址 -->
        <div class="oneBox area">
          <van-field
            v-model="liveAddress"
            is-link
            readonly
            name="area"
            input-align="right"
            label="现居城市"
            placeholder="请选择居住城市"
            @click="handleOnSelect('liveAddress')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <!--// 详细地址-->
          <van-field
            class="money"
            v-model="liveDetailAddress"
            label="详细地址"
            placeholder="请输入现居住地址"
            input-align="right"
            clearable
            @blur="inpFocus"
            @click-input="inpFocus"
          />
        </div>
        <div class="oneBox">
          <!-- 婚姻状态 -->
          <van-field
            v-model="marriage"
            is-link
            readonly
            name="picker"
            label="婚姻状态"
            input-align="right"
            placeholder="请选择婚姻状态"
            @click="handleOnSelect('marriage')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <!-- 学历 -->
          <van-field
            v-model.trim="educationCode"
            is-link
            readonly
            name="picker"
            label="学历"
            input-align="right"
            placeholder="请选择学历"
            @click="handleOnSelect('educationCode')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <!-- QQ/微信 -->
          <van-field
            class="money"
            v-model="qqWechar"
            label="QQ/微信"
            placeholder="请输入QQ/微信号"
            input-align="right"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            v-model="selfMonthIncome"
            is-link
            readonly
            name="picker"
            label="月收入"
            input-align="right"
            placeholder="请选择月收入"
            @click="handleOnSelect('selfMonthIncome')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <!-- 月收入 -->
        </div>

        <div class="work oneBox">
          <van-field
            v-model="workIndustry"
            is-link
            readonly
            name="workKind"
            label="工作行业"
            input-align="right"
            placeholder="请选择工作行业"
            @click="handleOnSelect('workIndustry')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            v-model="companyAddress"
            is-link
            readonly
            name="area"
            label="工作城市"
            input-align="right"
            placeholder="请选择工作城市"
            @click="handleOnSelect('companyAddress')"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            class="money"
            v-model="companyName"
            label="公司全称"
            placeholder="请输入公司全称"
            input-align="right"
            clearable
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            class="money"
            v-model="companyAddressDetail"
            label="工作地址"
            placeholder="请输入详细工作地址"
            input-align="right"
            clearable
            @blur="inpFocus"
            @click-input="inpFocus"
          />
        </div>

        <div class="oneBox">
          <van-field
            @click="getPostMessage('firstName')"
            class="money"
            :readonly="env === 'APP' ? true : false"
            v-model.trim="firstName"
            label="联系人1姓名"
            placeholder="请输入"
            input-align="right"
            clearable
            @blur="inpFocus"
            @click-input="inpFocus"
          >
            <template v-if="env === 'APP'" #button>
              <div class="address_btn">
                +通讯录
              </div>
            </template>
          </van-field>
          <van-field
            class="money"
            v-model.trim="firstPhone"
            :readonly="env === 'APP' ? true : false"
            label="联系人1电话"
            placeholder="请输入"
            input-align="right"
            clearable
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            v-model="firstRelation"
            is-link
            readonly
            input-align="right"
            name="picker"
            label="与您的关系"
            placeholder="请选择与您的关系"
            @blur="inpFocus"
            @click-input="inpFocus"
            @click="handleOnSelect('firstRelation')"
          />
          <van-field
            @click="getPostMessage('secondName')"
            class="money"
            v-model="secondName"
            label="联系人2姓名"
            placeholder="请输入"
            input-align="right"
            clearable
            :readonly="env === 'APP' ? true : false"
            @blur="inpFocus"
            @click-input="inpFocus"
          >
            <template v-if="env === 'APP'" #button>
              <div class="address_btn">
                +通讯录
              </div>
            </template>
          </van-field>
          <van-field
            class="money"
            v-model.trim="secondPhone"
            label="联系人2电话"
            placeholder="请输入"
            input-align="right"
            clearable
            :readonly="env === 'APP' ? true : false"
            @blur="inpFocus"
            @click-input="inpFocus"
          />
          <van-field
            v-model="secondRelation"
            is-link
            readonly
            input-align="right"
            name="picker"
            label="与您的关系"
            placeholder="请选择与您的关系"
            @blur="inpFocus"
            @click-input="inpFocus"
            @click="handleOnSelect('secondRelation')"
          />
        </div>
      </div>

      <!--下拉框-->
      <van-popup v-model:show="visible" position="bottom">
        <template
          v-if="keyName === 'liveAddress' || keyName === 'companyAddress'"
        >
          <van-area
            :area-list="areaList"
            @confirm="handleConfirm"
            @cancel="visible = false"
          />
        </template>
        <template v-else>
          <van-picker
            value-key="key"
            :columns="columns"
            @confirm="handleConfirm"
            @cancel="visible = false"
          />
        </template>
      </van-popup>

      <div class="base-btn" @click="handleSubmit">
        <span>确认并提交</span>
      </div>
      <div class="h-40"></div>
    </div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, reactive, toRefs, ref} from "vue";
import { useRouter } from "vue-router";
import API from "../../server/api";
import { areaList } from "@vant/area-data";
import Modal from "@/components/Modal/Modal.vue";
import { Toast, Dialog } from "vant";

export default {
  components: { Modal },
  setup() {
    const env = localStorage.getItem("env") || "h5";
    const isDisabled = reactive({
      isShow: null,
    });
    const Router = useRouter();
    const state = reactive({
      companyName: "",
      companyAddress: "",
      companyAddressDetail: "",
      liveAddress: "",
      liveDetailAddress: "",
      educationCode: "",
      marriage: "", // 婚姻状况
      selfMonthIncome: "",
      workIndustry: "",
      firstName: "",
      firstPhone: "",
      firstRelation: "",
      secondName: "",
      secondPhone: "",
      secondRelation: "",
      qqWechar: "",

      creditNo: "", // 授信单号
      lat: "",
      lng: "",
      phoneMail: "", //通讯录

      visible: false,
      keyName: "",
      columns: null,

      marriageList: [],
      eduPickerData: [],
      monthIncomeList: [],
      workkindList: [],
      relationList: [],
    });

    // 初始化基础信息的内容
    const initConfig = async () => {
      try {
        const res = await API.baseInfo({});
        state.eduPickerData = res.educationCode; // 学历
        state.marriageList = res.marriage; // 婚姻状态
        state.monthIncomeList = res.selfMonthIncome; // 月收入
        state.workkindList = res.workIndustry; // 工作行业
        state.relationList = res.relation;
      } catch (e) {
        console.log(e);
        Toast.error("初始化基础信息报错");
      }
    };

    // 处理下拉选择select的值
    const handleOnSelect = (name) => {
      switch (name) {
        case "marriage":
          state.columns = state.marriageList;
          break;
        case "educationCode":
          state.columns = state.eduPickerData;
          break;
        case "selfMonthIncome":
          state.columns = state.monthIncomeList;
          break;
        case "workIndustry":
          state.columns = state.workkindList;
          break;
        case "firstRelation":
        case "secondRelation":
          state.columns = state.relationList;
          break;
        case "liveAddress": // 生活城市
        case "companyAddress": // 工作城市
          state.columns = areaList;
          break;
      }
      state.keyName = name;
      state.visible = true;
    };

    // 下拉选择确认
    const handleConfirm = (e) => {
      switch (state.keyName) {
        case "liveAddress":
          state.liveAddress = e
            .filter((item) => !!item)
            .map((item) => item.name)
            .join("-");
          console.log(state.liveAddress, "沃日");
          state.liveCode = e
            .filter((item) => !!item)
            .map((item) => item.code)
            .join(",");
          break;
        case "companyAddress":
          state.companyAddress = e
            .filter((item) => !!item)
            .map((item) => item.name)
            .join("-");
          state.workCode = e
            .filter((item) => !!item)
            .map((item) => item.code)
            .join(",");
          break;
        default:
          state[state.keyName] = e.key;
          break;
      }

      state.visible = false;
    };

    // 提交验证
    const checkValidate = () => {
      let flag = true;
      if (!state.liveAddress) {
        Toast("请选择现居住城市");
        flag = false;
        return flag;
      }
      if (!state.liveDetailAddress) {
        Toast("请填写详细地址");
        flag = false;
        return flag;
      }
      if (!state.marriage) {
        Toast("请选择婚姻状况");
        flag = false;
        return flag;
      }
      if (!state.educationCode) {
        Toast("请选择学历");
        flag = false;
        return flag;
      }
      if (!state.qqWechar) {
        Toast("请填写QQ/微信号");
        flag = false;
        return flag;
      }
      if (!state.selfMonthIncome) {
        Toast("请选择月收入");
        flag = false;
        return flag;
      }

      if (!state.workIndustry) {
        Toast("请选择工作行业");
        flag = false;
        return flag;
      }
      if (!state.companyAddress) {
        Toast("请选择工作城市");
        flag = false;
        return flag;
      }
      if (!state.companyName) {
        Toast("请填写公司名称");
        flag = false;
        return flag;
      }
      if (!state.companyAddressDetail) {
        Toast("请填写工作地址");
        flag = false;
        return flag;
      }

      if (!state.firstName) {
        Toast("请输入联系人1的姓名");
        flag = false;
        return flag;
      }

      if (!state.firstPhone) {
        Toast("请输入联系人1的电话");
        flag = false;
        return flag;
      }

      if (!state.firstRelation) {
        Toast("请选择与联系人1的关系");
        flag = false;
        return flag;
      }

      if (!state.secondName) {
        Toast("请输入联系人2的姓名");
        flag = false;
        return flag;
      }
      if (!state.secondPhone) {
        Toast("请输入联系人2的电话");
        flag = false;
        return flag;
      }
      if (!state.secondRelation) {
        Toast("请选择与联系人2的关系");
        flag = false;
        return flag;
      }
      if (state.firstPhone === state.secondPhone) {
        Toast("联系人1与联系人2不能相同");
        flag = false;
        return flag;
      }
      return flag;
    };

    const formCachingInfo = () => {
      const isItCached = JSON.parse(localStorage.getItem("isItCached"));
      // 区分APP还是H5
      if (env === "APP") {
        formValue();
        return;
      }
      if (isItCached) {
        Dialog.confirm({
          title: "提示",
          message: "发现有未提交的数据，您是否希望继续使用或提交这些数据？",
        })
          .then(() => {
            formValue();
          })
          .catch(() => {
            localStorage.setItem("formCachingInfo", JSON.stringify({}));
            localStorage.setItem("isItCached", JSON.stringify(false));
            localStorage.setItem("firstName", "");
            localStorage.setItem("firstPhone", "");
            localStorage.setItem("secondName", "");
            localStorage.setItem("secondPhone", "");
          });
      }
    };
    // 表单赋值
    const formValue = () => {
      const {
        liveAddress,
        liveDetailAddress,
        marriage,
        educationCode,
        qqWechar,
        selfMonthIncome,
        workIndustry,
        companyAddress,
        companyName,
        companyAddressDetail,
        firstName,
        firstPhone,
        firstRelation,
        secondName,
        secondPhone,
        secondRelation,
      } = JSON.parse(localStorage.getItem("formCachingInfo"));
      state.liveAddress = liveAddress;
      state.liveDetailAddress = liveDetailAddress;
      state.marriage = marriage;
      state.educationCode = educationCode;
      state.qqWechar = qqWechar;
      state.selfMonthIncome = selfMonthIncome;
      state.workIndustry = workIndustry;
      state.companyAddress = companyAddress;
      state.companyName = companyName;
      state.companyAddressDetail = companyAddressDetail;
      state.firstName = localStorage.getItem("firstName") || firstName;
      state.firstPhone = localStorage.getItem("firstPhone") || firstPhone;
      state.firstRelation = firstRelation;
      state.secondName = localStorage.getItem("secondName") || secondName;
      state.secondPhone = localStorage.getItem("secondPhone") || secondPhone;
      state.secondRelation = secondRelation;
    };
    // 基础信息表单提交
    const handleSubmit = async () => {
      const isCheck = checkValidate();
      if (!isCheck) return;
      try {
        const form = Object.assign(
          {},
          {
            companyName: state.companyName,
            companyAddress: state.companyAddress,
            companyAddressDetail: state.companyAddressDetail,

            liveAddress: state.liveAddress, // 现居地址
            liveDetailAddress: state.liveDetailAddress, // 现居详细地址

            educationCode: state.educationCode,
            marriage: state.marriage,
            selfMonthIncome: state.selfMonthIncome,
            workIndustry: state.workIndustry,
            firstRelation: state.firstRelation,
            secondRelation: state.secondRelation,

            firstName: state.firstName,
            firstPhone: state.firstPhone,

            secondName: state.secondName,
            secondPhone: state.secondPhone,

            qqWechar: state.qqWechar,

            creditNo: state.creditNo,
            clientCity: "",
            clientProvince: "",
            clientRegion: "",
            link: "",
            lat: state.lat,
            lng: state.lng,
          }
        );

        // 处理数据
        state.eduPickerData.forEach((item) => {
          if (form.educationCode === item.key) {
            form.educationCode = item.value;
          }
        });

        // 处理数据
        state.marriageList.forEach((item) => {
          if (form.marriage === item.key) {
            form.marriage = item.value;
          }
        });

        state.monthIncomeList.forEach((item) => {
          if (form.selfMonthIncome === item.key) {
            form.selfMonthIncome = item.value;
          }
        });

        state.workkindList.forEach((item) => {
          if (form.workIndustry === item.key) {
            form.workIndustry = item.value;
          }
        });

        state.relationList.forEach((item) => {
          if (form.firstRelation === item.key) {
            form.firstRelation = item.value;
          }
          if (form.secondRelation === item.key) {
            form.secondRelation = item.value;
          }
        });

        const res = await API.baseSubmit({ ...form });
        // 成功跳转到信息提交页
        if (res.success) {
          Toast.success("操作成功");
          localStorage.setItem("formCachingInfo", JSON.stringify({}));
          localStorage.setItem("isItCached", JSON.stringify(false));
          localStorage.setItem("firstName", "");
          localStorage.setItem("firstPhone", "");
          localStorage.setItem("secondName", "");
          localStorage.setItem("secondPhone", "");
          setTimeout(() => {
            Router.back();
          }, 1500);
        }
      } catch (err) {}
    };

    // 获取基础信息（反显）
    const init = async () => {
      try {
        const res = await API.getBaseInfo();
        if (res.success && res.firstName) {
          state.companyName = res.companyName;
          state.companyAddress = res.companyAddress;
          state.companyAddressDetail = res.companyAddressDetail;

          state.liveAddress = res.liveAddress;
          state.liveDetailAddress = res.liveDetailAddress;

          state.firstName = res.firstName;
          state.firstPhone = res.firstPhone;
          state.secondName = res.secondName;
          state.secondPhone = res.secondPhone;
          state.qqWechar = res.qqWechar;

          // 处理数据
          state.eduPickerData.forEach((item) => {
            if (res.educationCode === item.value) {
              state.educationCode = item.key;
            }
          });

          // 处理数据
          state.marriageList.forEach((item) => {
            if (res.marriage === item.value) {
              state.marriage = item.key;
            }
          });

          state.monthIncomeList.forEach((item) => {
            if (res.selfMonthIncome === item.value) {
              state.selfMonthIncome = item.key;
            }
          });

          state.workkindList.forEach((item) => {
            if (res.workIndustry === item.value) {
              state.workIndustry = item.key;
            }
          });

          state.relationList.forEach((item) => {
            if (res.firstRelation === item.value) {
              state.firstRelation = item.key;
            }
            if (res.secondRelation === item.value) {
              state.secondRelation = item.key;
            }
          });
          // formCachingInfo();
        } else { 
          const isItCached = JSON.parse(localStorage.getItem("isItCached"));
          if (isItCached) {
            console.log('有缓存');
            formCachingInfo();
          }
        }
      } catch (err) {
        Toast(err.message);
        console.log(err.message);
      }
    };

    onMounted(async () => {
      document.body.style.overflow = "hidden";

      let showC = localStorage.getItem("isDisabled") || false
      console.log('---------isDisabled---------');
      console.log(JSON.stringify(showC));
      console.log('---------isDisabled---------');
      if (showC) {
        isDisabled.isShow = false
      } else {
        isDisabled.isShow = true
      }
      console.log('--------isShow----------');
      console.log(JSON.stringify(isDisabled.isShow));
      console.log('--------isShow----------');
      state.creditNo = localStorage.getItem("creditNo");
      const location = localStorage.getItem("locationInfo");
      if (location != null) {
        const { lat, lng } = JSON.parse(location);
        state.lat = lat;
        state.lng = lng;
      } else {
        console.log("GetLocation68");
        // Toast("获取地理位置信息异常，请返回后重新进入");
      }
      // 初始化配置
      await initConfig();
      await init();

      // 判断是否有未提交数据，如果有那就使用未提交，如果没有就走接口
      // const isItCached = JSON.parse(localStorage.getItem("isItCached"));
      // if (isItCached) {
      //   console.log('有缓存');
      //   await formCachingInfo();
      // } else { 
      //   // 初始化基础信息
      //   console.log('无缓存');
      //   await init();
      // }

    });
    const inpFocus = () => {
      console.log('inpFocus');
      localStorage.setItem("formCachingInfo", JSON.stringify(state));
      localStorage.setItem("isItCached", JSON.stringify(true));
      let result;
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // 判断iPhone|iPad|iPod|iOS
        result = "ios";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // 判断Android
        result = "android";
      }
      if (result == "ios") {
        const ts = setTimeout(() => {
          clearImmediate(ts);
          var scrollHeight =
            document.documentElement.scrollTop || document.body.scrollTop || 0;
          window.scrollTo(0, Math.max(scrollHeight - 1, 0));
        }, 100);
      }
    };
    // 通知APP
    const getPostMessage = (type, isShow) => {
      localStorage.setItem("formCachingInfo", JSON.stringify(state));
      localStorage.setItem("isItCached", JSON.stringify(true));
      uni.postMessage({
        data: {
          action: "GetContact",
          type,
          params: { userId: localStorage.getItem('userId') }
        },
      });
    };
    return {
      env,
      ...toRefs(state),
      areaList,

      handleSubmit,
      formCachingInfo,
      handleOnSelect,
      handleConfirm,
      //
      inpFocus,
      getPostMessage,
      formValue,
      isDisabled
    };
  },
};
</script>
<style>
.work .van-field__control {
  text-align: right;
}

.base-page .van-cell_label {
  color: #212121;
}

.base-page .van-cell__title.van-field__label {
  color: #212121;
}
.h-40 {
  height: 40px;
}

.address_btn {
  color: plum !important;
}
</style>
