<template>
  <div class="payType">
    <div class="df fdc alc jcc info-box">
      <div>
        <span class="repayAmount-icon">¥</span
        ><span class="repayAmount-money FZFW">{{
          $filters.formatMoney(data.repayAmount, 2)
        }}</span>
      </div>
      <div class="meet">
        订单应付 ¥{{ $filters.formatMoney(data.repayAmount, 2) }}
      </div>
    </div>

    <!-- 选择支付方式 -->
    <div
      @click="Jump('/pay')"
      class="bg-white mx-30 p-30 rounded df alc jcsb item-shadow"
    >
      <div class="df alc">
        <img src="./img/ic_1.png" class="icon-type" />
        <div class="label-type ml-15">银行卡快捷支付</div>
      </div>
      <van-icon name="arrow" size="16px" color="#333" />
    </div>
    <div
      @click="getCheckoutUrl"
      class="bg-white mx-30 p-30 rounded df alc jcsb item-shadow mt-30"
    >
      <div class="">
        <div class="df alc">
          <img src="./img/ic_2.png" class="icon-type" />
          <div class="df fdc jcc ml-15">
            <div class="label-type ">收银台支付</div>
            <div class="meet ft-20 ">微信支付、支付宝支付等</div>
          </div>
        </div>
      </div>
      <van-icon name="arrow" size="16px" color="#333" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import API from "../../server/api";
import { Jump } from "@/utils/routh";

export default {
  setup() {
    const env = localStorage.getItem("env") || 'h5'
    const Router = useRouter();
    const data = ref({});
    const timer = ref(null);
    onMounted(() => {
      data.value = JSON.parse(localStorage.getItem("backResult"));
      // 获取 backedNo
      let backedNo = localStorage.getItem("backedNo");
      if (backedNo) {
        // 验证是否还款成功
        getCheckoutResult(backedNo);
      }
    });
    // 验证是否还款成功
    const getCheckoutResult = async backedNo => {
      let parmas = {
        backedNo
      };
      const { code, result } = await API.getCheckoutResult("LKL", parmas);
      if (code === "200") {
        if (result === "SUCCESS") {
          Toast("还款成功");
          localStorage.setItem("backedNo", "");
          localStorage.removeItem("backedNo");
          const timer = setTimeout(() => {
            clearImmediate(timer);
            Router.push("/payResult");
          }, 1000);
        } else if (result === "FAIL") {
          Toast("支付失败");
        } else if (result === "SUBMITED") {
          Toast("支付中");
        }
        clearInterval(timer.value)
      }
    };
    // 获取收银台url
    const getCheckoutUrl = async () => {
      try {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0
        });
        // 处理还款期数
        const lastInfo = localStorage.getItem("backResult");
        const lastInfoParse = JSON.parse(lastInfo);
        let params = {
          loanNo: localStorage.getItem("loanNos"),
          periods: lastInfoParse.list
        };
        const { code, result } = await API.getCheckoutUrl("LKL", params);
        if (code === "200") {
          const { backedNo, payUrl } = result;
          localStorage.setItem("backedNo", backedNo);
          Toast.clear();
          // if (env == 'APP') { 
          //   uni.postMessage({
          //     data: {
          //       action: "getCheckoutUrl",
          //       params: {
          //         url: payUrl
          //       }
          //     }
          //   })
          //   return
          // }
          window.location.href = payUrl;
          timer.value = setInterval(() => {
            getCheckoutResult(backedNo);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        Toast.clear();
      }
    };
    onUnmounted(() => {
      console.log("onUnmounted");
      localStorage.removeItem("backedNo");
      clearInterval(timer.value);
    });
    return {
      env,
      data,
      Jump,
      getCheckoutUrl
    };
  }
};
</script>
<style lang="less" scoped>
.payType {
  background: #ededed;
  height: 100vh;
}

.bg-white {
  background: #fff;
}

.info-box {
  padding-top: 150px;
  margin-bottom: 100px;
}

.meet {
  margin-top: 10px;
  font-size: 24px;
  color: #afafaf;
}

.ft-20 {
  font-size: 20px;
}

.repayAmount-icon {
  font-size: 40px;
}

.repayAmount-money {
  font-size: 60px;
  margin-left: 10px;
  font-weight: bold;
}

.mx-30 {
  margin: 0 30px;
}

.p-30 {
  padding: 30px 20px 30px 30px;
}

.rounded {
  border-radius: 25px;
}

.mt-30 {
  margin-top: 30px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-50 {
  margin-bottom: 50px;
}
.icon-type {
  width: 50px;
  height: 50px;
}

.ml-15 {
  margin-left: 15px;
}
.label-type {
  font-size: 28px;
  // font-weight: bold;
  color: #333;
}

.item-shadow {
  box-shadow: 0px 6px 12px 0px rgba(226, 226, 231, 0.3);
}

.jcsb {
  justify-content: space-between;
}
</style>
