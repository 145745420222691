<template>
  <div class="success">
    <div class="nobill-content df jcc fdc alc">
        <img src="./img/bill-bgc.png" alt="" />
        <p>目前还没有数据哦</p>
      </div>
  </div>
</template>

<script>
export default {
  setup () {
    return { }
  }
}
</script>

<style lang="less" scoped></style>
